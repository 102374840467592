<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="d-flex flex-row flex-wrap justify-space-between">
        <span>{{ cartTitle }}</span>
      </v-list-item-title>
      <v-list-item-subtitle class="d-flex flex-row flex-wrap justify-start mt-1">
        <v-chip dense small label class="me-1" :color="cartStatusColor">{{ cartStatusString }}</v-chip>
        <v-chip small dense label class="me-1" :color="cartBranchStatusColor">{{ cartBranchStatus }}</v-chip>
        <v-chip small dense label :color="cartPostStatusColor">{{ cartPostStatus }}</v-chip>
      </v-list-item-subtitle>
      <v-list-item-subtitle class="mt-1">
        <v-chip dense x-small label color="secondary">
          {{ cartPurchaseTimestampString }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="d-flex flex-column justify-center align-center">
      <v-dialog max-width="766">
        <template v-slot:activator="{on}">
          <v-btn text small v-on="on">{{ $t('components.website.cartItem.details') }}</v-btn>
        </template>
        <carts-history-details :cart="cart" />
      </v-dialog>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  import CartDetails from '@peynman/press-vue-core/mixins/CartDetails'
  import CartsHistoryDetails from './CartsHistoryDetails.vue'

  export default {
    name: 'CartsHistoryItem',
    components: {
      CartsHistoryDetails,
    },
    mixins: [
      CartDetails,
    ],
    props: {
      cart: Object,
    },
  }
</script>
