<template>
  <website>
    <v-card
      :dark="theme.website.cards.dark"
      :light="theme.website.cards.light"
      :color="theme.website.cards.color"
    >
      <validations-alert
        v-if="hasAlert"
        :color="alertColor"
        :message="alertMessage"
        :type="alertType"
      />
    </v-card>
    <carts-history-card />
  </website>
</template>

<script>
  import ValidationsAlert from '@peynman/press-vue-admin/components/Inputs/ValidationsAlert/ValidationsAlert.vue'
  import Website from '../templates/Website/Website.vue'
  import ResponseAlerts from '@peynman/press-vue-core/mixins/ResponseAlerts'
  import CartsHistoryCard from '../components/UserCart/CartsHistoryCard.vue'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import Constants from '../constants'

  export default {
    name: 'PurchasingCart',
    components: {
      Website,
      ValidationsAlert,
      CartsHistoryCard,
    },
    mixins: [
      ResponseAlerts,
      Themeable,
    ],
    mounted () {
      if (this.alertType === 'success') {
        this.$store.dispatch('cart/fetchCartsHistory', 1).then(history => {
          this.$store.dispatch('analytics/trackGoalConversion', {
            goalId: Constants.Matomo.Goals.success_purchase,
            revenue: history[0].amount,
          })
        })
      }
    },
  }
</script>
