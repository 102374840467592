<template>
  <v-card
    :color="theme.website.userCart.card.color"
    :light="theme.website.userCart.card.light"
    :dark="theme.website.userCart.card.dark"
  >
    <v-card-title>{{ $t('components.website.cartItem.detailsTitle', { id: $n(cart.id, 'decimal') }) }}</v-card-title>
    <v-card-text>
      <user-cart-list :cart="cart" preview />
    </v-card-text>
    <v-card-text>
      <cart-delivery :cart="cart" />
      <v-divider />
      <cart-amount :cart="cart" selected-delivery />
    </v-card-text>
  </v-card>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import UserCartList from './UserCartList.vue'
  import UserCartDetails from '@peynman/press-vue-core/mixins/UserCartDetails'
  import CartAmount from './CartAmount.vue'
  import CartDelivery from './CartDelivery.vue'

  export default {
    name: 'CartsHistoryDetails',
    components: {
      UserCartList,
      CartAmount,
      CartDelivery,
    },
    mixins: [
      Themeable,
      UserCartDetails,
    ],
    props: {
      cart: Object,
    },
  }
</script>
